'use strict';

import 'core-js';
import 'bootstrap';
import '../styles/sass/basic.scss';

const Backbone = require('backbone');
const Marionette = require('backbone.marionette');
const i18next = require('i18next');
const i18nextXHR = require('i18next-xhr-backend');
const parseUrl = require('url-parse');
const _ = require('underscore');
const simpleStorage = require('simplestorage.js');
const xssFilters = require('xss-filters');
const $ = require('jquery');
const Constants = require('./Constants');
const controller = require('./controllers/controller');

const extractBaseDomainFromUrl = (fullUrl) => parseUrl(fullUrl, {})
  .hostname
  .split('.')
  .slice(-2)
  .join('.');

const App = new Marionette.Application({
  returnPage:    null,
  onBeforeStart: function fOnBeforeStart() {
    const startUrl = parseUrl(window.location.href, true);
    if (!_.isEmpty(startUrl.query) && _.has(startUrl.query, 'returnPage')) {
      this.returnPage = xssFilters.uriInUnQuotedAttr(startUrl.query.returnPage);
    }
    if (!_.isEmpty(startUrl.query) && _.has(startUrl.query, 'native_login')) {
      simpleStorage.set(Constants.cookieLawStorageKey, true);
      simpleStorage.set(Constants.nativeClient, true);
    }
    if (!_.isEmpty(startUrl.query) && _.has(startUrl.query, 'native_guest_version')) {
      simpleStorage.set(Constants.nativeClientVersion, startUrl.query.native_guest_version);
    }
    const landingPage = startUrl.query.redirectUrl;
    if (!_.isEmpty(landingPage)) {
      const sanitisedLandingPage = xssFilters.uriInUnQuotedAttr(landingPage);
      const landingPageBaseDomain = extractBaseDomainFromUrl(sanitisedLandingPage);
      const currentBaseDomain = extractBaseDomainFromUrl(window.location);
      if (landingPageBaseDomain && currentBaseDomain === landingPageBaseDomain) {
        simpleStorage.set(Constants.landingPageStorageKey, sanitisedLandingPage);
      }
    }
  },
  onStart: function fOnStart() {
    Backbone.history.start({ pushState: true });

    $.ajaxSetup({
      xhrFields: {
        withCredentials: true,
      },
      crossDomain: true,
    });

    controller({
      returnPage:    this.returnPage,
      initialRoutes: {
        login:                      'USERNAME',
        changeEmail:                'CHANGE_EMAIL',
        changeEmailCancel:          'CHANGE_EMAIL_CANCEL',
        resumeSession:              'RESUME_SESSION',
        trialVerify:                'TRIAL_VERIFY',
        trialSetup:                 'TRIAL_SETUP',
        oAuthAuthorizeInit:         'OAUTH_AUTHORIZE_INIT',
        accountSetup:               'ACCOUNT_SETUP',
        mfaEmailSwitchAccountStart: 'MFA_EMAIL_SWITCH_ACCOUNT_START',
      },
    });
    if (simpleStorage.get(Constants.nativeClient) === true) {
      $(document.body).addClass('native-client');
    }
    if (!NETOP_CONFIG.CAPTCHA_KEY) {
      $(document.body).addClass('captcha-disabled');
    }
  },
});

i18next
  .use(i18nextXHR)
  .init({
    lng:         'en',
    fallbackLng: 'en',
    ns:          ['common', 'notification', 'error', 'scope'],
    backend:     {
      loadPath:          '/translations/{{lng}}/{{ns}}.json',
      queryStringParams: {
        v: NETOP_CONFIG.PORTAL_VERSION,
      },
    },
  }, () => {
    App.start();
  });
