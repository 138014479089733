'use strict';

const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const i18n = require('i18next');
const WarningView = require('./components/warningView');
const template = require('../templates/forgotpassword.hbs');
const CaptchaView = require('./components/captchaView');
const guestCaptcha = require('./components/guestCaptcha');

const navChannel = Radio.channel('controller');

const ajaxOptions = {
  url:    `${NETOP_CONFIG.SECURE_API}/password/request-reset`,
  method: 'POST',
};

const validationReg = {
  email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
};

const ForgotPasswordView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    email:       '#email',
    error:       '.error',
    input_error: '.input-error',
    closeA:      '.close',
    cancel:      '#cancel',
    form_group:  '.form-group',
    sent_email:  '.sent-email',
    captcha:     '.captcha-container',
  },

  events: {
    'input @ui.email':  'onFocusEmail',
    'focus @ui.email':  'onFocusEmail',
    'blur @ui.email':   'onBlurEmail',
    'submit form':      'handleSubmit',
    'click @ui.cancel': 'onCancel',
  },

  initialize: function fInitialize({ model, options = {} } = {}) {
    this.model = model;
    this.captchaView = null;
    this.captchaRequired = !!options.captchaEnabled;
  },

  onBeforeDestroy: function fOnBeforeDestroy() {
    if (this.captchaView) {
      this.captchaView.destroy();
    }
  },

  onFocusEmail: function fOnFocusEmail() {
    this.ui.form_group.addClass('floating-label-active');
  },

  onBlurEmail: function fOnFocusEmail() {
    if (!this.ui.email[0].value) {
      this.ui.form_group.removeClass('floating-label-active');
    }
  },

  onCancel: function fOnCancel(event) {
    event.preventDefault();
    navChannel.trigger('USERNAME');
  },

  // captcha handlers
  onCaptchaResponse: function fCaptchaResponse() {
    this.submitData();
  },
  onCaptchaError: function fCaptchaError(err) {
    if (err && err.name === guestCaptcha.GUEST_CAPTCHA_NOT_PRESENT_ERROR.name) {
      // eslint-disable-next-line
      this.warningView.show(i18n.t('common:guest-captcha-error', { url: NETOP_CONFIG.PORTAL_FRONTEND }));
      this.showContent(false);
    } else {
      this.warningView.show(i18n.t('common:captcha-error'));
    }
    this.setBusy(false);
  },
  onCaptchaExpired: function fCaptchaExpired() {
    this.captchaView.reset();
  },
  showCaptcha: function fDisplayCaptcha(show) {
    if (show) {
      this.captchaView.$el.show();
    } else if (this.captchaView) {
      this.captchaView.$el.hide();
    }
  },
  showContent: function fDisplayContent(show) {
    if (show) {
      $('#step-content').show();
      setTimeout(() => $(this.ui.email).focus(), 1);
    } else {
      $('#step-content').hide();
    }
  },

  setBusy: function fSetBusy(show) {
    const $busy = $('.modal-container-busy-layer');
    if (show) {
      $busy.show();
      $busy.focus();
    } else {
      $busy.hide();
      setTimeout(() => $(this.ui.email).focus(), 1);
    }
  },

  executeCaptchaChallange: function fExecuteCaptchaChallange() {
    if (!this.captchaRequired) {
      return;
    }
    if (!this.captchaView) {
      this.captchaView = new CaptchaView({
        el: this.ui.captcha,
      });
      this.captchaView.on('captcha:response', this.onCaptchaResponse.bind(this));
      this.captchaView.on('captcha:challange:hidden', () => this.setBusy(false));
      this.captchaView.on('captcha:expired', this.onCaptchaExpired.bind(this));
      this.captchaView.on('captcha:error', this.onCaptchaError.bind(this));
      this.captchaView.on('captcha:rendered', () => {
        this.captchaView.reset();
        this.captchaView.execute();
      });
      this.captchaView.render();
    } else {
      this.captchaView.reset();
      this.captchaView.execute();
    }
  },
  // end captcha handlers

  handleSubmit: function fHandleSubmit(event) {
    event.preventDefault();

    const email = this.ui.email.val();

    if (!validationReg.email.test(email)) {
      if (!email) {
        this.warningView.show(i18n.t('common:mandatory-field'));
      } else {
        this.warningView.show(i18n.t('common:forgot-password-error'));
      }
      this.ui.email.parent().addClass('has-error');
      $(this.ui.email).focus();
      return;
    }
    this.ui.email.parent().removeClass('has-error');
    this.warningView.hide();

    if (this.captchaRequired && !(this.captchaView && this.captchaView.token)) {
      this.setBusy(true);
      this.executeCaptchaChallange();
      // the captcha response event handler will call submitData
      return;
    }
    this.submitData();
  },

  submitData: function fSubmitData() {
    const email = this.ui.email.val();

    this.model.set({
      email,
    });

    $.ajax({
      url:    ajaxOptions.url,
      method: ajaxOptions.method,
      data:   {
        email:   this.model.get('email'),
        captcha: this.captchaView && this.captchaView.token ? this.captchaView.token : null,
      },
    })
      .done((response = {}) => {
        if (this.captchaView) {
          this.captchaView.reset();
        }
        this.setBusy(false);
        const { error = {} } = response;
        this.ui.email.val('');
        this.ui.email.parent().removeClass('has-error');
        this.ui.email.focus();
        if (error === '30009') {
          this.warningView.show(i18n.t('common:captcha-error-response'));
          if (guestCaptcha.guestCaptchaNotPresent()) {
            // can happen in guest ?
            this.onCaptchaError(guestCaptcha.GUEST_CAPTCHA_NOT_PRESENT_ERROR);
          }
        }
        navChannel.trigger('FORGOT_PASSWORD_SUCCESS');
      })
      .fail((error) => {
        this.setBusy(false);
        console.error('Error happened', error);
        navChannel.trigger('FORGOT_PASSWORD_SUCCESS');
      });
  },

  onRender: function fOnRender() {
    this.warningView = new WarningView({
      el: this.ui.input_error,
    });
    setTimeout(() => {
      this.ui.email.focus();
      if (this.captchaRequired) {
        if (guestCaptcha.guestCaptchaNotPresent()) {
          // we can reach here in the windows guest, if the username is already demanding captcha
          this.onCaptchaError(guestCaptcha.GUEST_CAPTCHA_NOT_PRESENT_ERROR);
        }
      }
    }, 1); // Doesn't work without timeout, cba with proper fix
  },
});

module.exports = ForgotPasswordView;
