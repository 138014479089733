var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal-container\">\n    <div><div class=\"main-title\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:change-password-title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":72}}}))
    + "</div></div>\n    <div class=\"form-group floating-label\">\n        <p>"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:change-password-success-text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":57}}}))
    + "</p>\n    </div>\n    <div class=\"form-group\">\n        <a href=\"#\" id=\"cancel\">"
    + alias2(__default(require("../handlebars-helpers/i18n.js")).call(alias1,"common:return-to-login",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":32},"end":{"line":7,"column":65}}}))
    + "</a>\n    </div>\n</div>\n";
},"useData":true});