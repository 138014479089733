'use strict';

const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const i18n = require('i18next');
const _ = require('underscore');
const WarningView = require('./components/warningView');
const ErrorView = require('./components/msgBoxView');
const template = require('../templates/password.hbs');
const CaptchaView = require('./components/captchaView');
const guestCaptcha = require('./components/guestCaptcha');

const navChannel = Radio.channel('controller');

const ajaxOptions = {
  url:    `${NETOP_CONFIG.SECURE_API}/nextStep`,
  method: 'POST',
};

const PasswordView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    password:       '#password',
    error:          '.error',
    input_error:    '.input-error',
    closeA:         '.close',
    cancel:         '#cancel',
    form_group:     '.form-group',
    forgotPassword: '#forgot-password',
    captcha:        '.captcha-container',
  },
  events: {
    'submit form':              'handleSubmit',
    'click @ui.closeA':         'handleCloseAlert',
    'click @ui.cancel':         'handleClickCancel',
    'click @ui.forgotPassword': 'handleClickForgotPassword',
    'input @ui.password':       'onFocusPassword',
    'focus @ui.password':       'onFocusPassword',
    'blur @ui.password':        'onBlurPassword',
  },

  initialize: function fInitialize({ options = {} } = {}) {
    // flag, captcha should be rendered and the challange executed
    this.captchaRequired = !!options.captcha;
    this.captchaEnabled = !!options.captchaEnabled;
  },

  onBeforeDestroy: function fOnBeforeDestroy() {
    if (this.captchaView) {
      this.captchaView.destroy();
    }
  },

  handleClickCancel: function fHandleClickCancel(event) {
    event.preventDefault();
    const authorize = this.model.get('authorize');
    const inviteLogin = this.model.get('inviteLogin');
    const inviteKey = this.model.get('inviteKey');
    const invitedBy = this.model.get('invitedBy');
    const username = this.model.get('username');

    this.model.clear();
    if (inviteLogin) {
      this.model.set({
        inviteLogin,
        inviteKey,
        invitedBy,
        username,
      });
    }
    if (authorize) {
      this.model.set({ authorize });
    }
    navChannel.trigger('USERNAME');
  },

  handleClickForgotPassword: function fHandleClickForgotPassword(event) {
    event.preventDefault();
    this.model.clear();
    navChannel.trigger('FORGOT_PASSWORD', { captchaEnabled: this.captchaEnabled });
  },

  onFocusPassword: function fOnFocusPassword() {
    this.ui.form_group.addClass('floating-label-active');
  },

  onBlurPassword: function fOnBlurPassword() {
    if (!this.ui.password[0].value) {
      this.ui.form_group.removeClass('floating-label-active');
    }
  },

  onCaptchaResponse: function fCaptchaResponse() {
    this.submitData();
  },
  onCaptchaError: function fCaptchaError(err) {
    if (err && err.name === guestCaptcha.GUEST_CAPTCHA_NOT_PRESENT_ERROR.name) {
      // eslint-disable-next-line
      this.errorView.show(i18n.t('common:guest-captcha-error', { url: NETOP_CONFIG.PORTAL_FRONTEND }));
      this.showContent(false);
    } else {
      this.errorView.show(i18n.t('common:captcha-error'));
    }
    this.setBusy(false);
  },
  onCaptchaExpired: function fCaptchaExpired() {
    this.captchaView.reset();
  },
  showCaptcha: function fDisplayCaptcha(show) {
    if (show) {
      this.captchaView.$el.show();
    } else if (this.captchaView) {
      this.captchaView.$el.hide();
    }
  },
  showContent: function fDisplayContent(show) {
    if (show) {
      $('#step-content').show();
      setTimeout(() => this.ui.password.focus(), 1);
    } else {
      $('#step-content').hide();
    }
  },

  setBusy: function fSetBusy(show) {
    const $busy = $('.modal-container-busy-layer');
    if (show) {
      $busy.show();
      $busy.focus();
    } else {
      $busy.hide();
      setTimeout(() => this.ui.password.focus(), 1);
    }
  },

  executeCaptchaChallange: function fExecuteCaptchaChallange() {
    if (!this.captchaRequired) {
      return;
    }
    if (!this.captchaView) {
      this.captchaView = new CaptchaView({
        el: this.ui.captcha,
      });
      this.captchaView.on('captcha:response', this.onCaptchaResponse.bind(this));
      this.captchaView.on('captcha:challange:hidden', () => this.setBusy(false));
      this.captchaView.on('captcha:expired', this.onCaptchaExpired.bind(this));
      this.captchaView.on('captcha:error', this.onCaptchaError.bind(this));
      this.captchaView.on('captcha:rendered', () => {
        this.captchaView.reset();
        this.captchaView.execute();
      });
      this.captchaView.render();
    } else {
      this.captchaView.reset();
      this.captchaView.execute();
    }
  },
  handleSubmit: function fHandleSubmit(e) {
    e.preventDefault();

    const password = this.ui.password.val();
    if (!password) {
      this.warningView.show(i18n.t('common:mandatory-field'));
      this.ui.password.parent().addClass('has-error');
      this.ui.password.focus();
      return;
    }
    this.ui.password.parent().removeClass('has-error');
    this.warningView.hide();
    if (this.captchaRequired && !(this.captchaView && this.captchaView.token)) {
      this.setBusy(true);
      this.executeCaptchaChallange();
      // the captcha response event handler will call submitData
      return;
    }
    this.submitData();
  },

  submitData: function fSubmitData() {
    const password = this.ui.password.val();
    this.model.set({
      password,
    });

    $.ajax({
      url:    ajaxOptions.url,
      method: ajaxOptions.method,
      data:   {
        loginSessionId: this.model.get('loginSessionId'),
        currentStep:    this.model.get('nextStep'),
        input:          this.model.get('password'),
        captcha:        this.captchaView && this.captchaView.token ? this.captchaView.token : null,
      },
    })
      .done((body) => {
        if (body.nextStep === 'ERROR') {
          this.model.set(_.extend({}, body, (body.options || {})));
          navChannel.trigger(this.model.get('nextStep'));
          return;
        }

        if (this.captchaView) {
          this.captchaView.reset();
        }

        this.setBusy(false);
        if (body.options.error) {
          this.ui.password.parent().addClass('has-error');
          this.ui.password.val('');
          this.ui.password.focus();

          switch (body.options.error) {
            case '30007':
              this.warningView.show(i18n.t('common:credential-error'));
              this.errorView.hide();
              this.captchaRequired = body.captcha;
              break;
            case '30009':
              this.warningView.show(i18n.t('common:captcha-error-response'));
              this.errorView.hide();
              this.captchaRequired = body.captcha;
              if (guestCaptcha.guestCaptchaNotPresent()) {
                // we can reach here in the guest, if the username is already demanding captcha
                // but it's the first response to the pwd step (the captchaView is not yet rendered)
                this.onCaptchaError(guestCaptcha.GUEST_CAPTCHA_NOT_PRESENT_ERROR);
              }
              break;
            default:
              console.error(`Error happened (error code: ${body.options.error})`);
              this.errorView.show(i18n.t('common:unknown-error'));
              this.warningView.hide();
              break;
          }

          return;
        }

        this.setBusy(false);
        const result = {
          loginSessionId: body.loginSessionId,
          nextStep:       body.nextStep,
          options:        body.options || {},
          token:          (body.options || { token: null }).token,
        };
        this.model.set(result);
        navChannel.trigger(this.model.get('nextStep'));
      })
      .fail((error) => {
        console.error(`Error happened (error code: ${error})`);
        this.setBusy(false);
        this.errorView.show(i18n.t('common:unknown-error'));
        this.warningView.hide();
      });
  },

  onRender: function fOnRender() {
    this.errorView = new ErrorView({
      el: this.ui.error,
    });
    this.warningView = new WarningView({
      el: this.ui.input_error,
    });
    setTimeout(() => {
      this.ui.password.focus();
      if (this.captchaRequired) {
        if (guestCaptcha.guestCaptchaNotPresent()) {
          // we can reach here in the windows guest, if the username is already demanding captcha
          this.onCaptchaError(guestCaptcha.GUEST_CAPTCHA_NOT_PRESENT_ERROR);
        }
      }
    }, 1); // Doesn't work without timeout, cba with proper fix
  },
});

module.exports = PasswordView;
