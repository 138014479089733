'use strict';

/* eslint-disable class-methods-use-this, prefer-rest-params, prefer-spread, no-prototype-builtins */

const _ = require('underscore');
const Backbone = require('backbone');
const Info = require('../models/info');
const Changelogs = require('./changelog');

class InternalInfosMock {
  fetch() {
    const mockedResult = [];
    return $.Deferred().resolve([mockedResult]).promise();
  }

  getName() {
    return 'internalInfosMock';
  }
}

const initialize = function fInitialize() {
  this.collections = [new Changelogs(), new InternalInfosMock()];
};

const fetch = function fFetch() {
  const fetchPromises = _.map(this.collections, (collection) => collection.fetch());

  let models = [];

  return $.when.apply($, fetchPromises).then(_.bind(function onResolvedAll() {
    _.each(arguments, (fetchResult) => {
      const rawPayload = _.first(fetchResult);

      if (_.isObject(rawPayload) && rawPayload.hasOwnProperty('rows')) {
        models = _.union(models, rawPayload.rows);
      } else if (_.isArray(rawPayload)) {
        models = _.union(models, rawPayload);
      }
    });

    this.reset(models);
    return models;
  }, this));
};

module.exports = Backbone.Collection.extend({
  model: Info,
  fetch,
  initialize,
});
