'use strict';

const _ = require('underscore');
const simpleStorage = require('simplestorage.js');
const Marionette = require('backbone.marionette');
const $ = require('jquery');
const Radio = require('backbone.radio');
const i18n = require('i18next');
const WarningView = require('./components/warningView');
const ErrorView = require('./components/msgBoxView');

const navChannel = Radio.channel('controller');
const Constants = require('../Constants');
const template = require('../templates/username.hbs');
const utils = require('../utils');

const ajaxOptions = {
  url:    `${NETOP_CONFIG.SECURE_API}/startLogin`,
  method: 'POST',
};

const UserNameView = Marionette.ItemView.extend({
  template,
  tagName: 'div',
  ui:      {
    username_label: '#username_label',
    username:       '#username',
    form_group:     '.form-group',
    error:          '.error',
    input_error:    '.input-error',
    sub_message:    '.sub-message',
  },
  events: {
    'input @ui.username': 'onFocusUsername',
    'focus @ui.username': 'onFocusUsername',
    'blur @ui.username':  'onBlurUsername',
    'submit form':        'handleSubmit',
  },

  initialize: function fInitialize(args) {
    this.model = args.model;
  },

  onFocusUsername: function fOnFocusUsername() {
    this.ui.form_group.addClass('floating-label-acetive');
  },

  onBlurUsername: function fOnFocusUsername() {
    if (!this.ui.username[0].value) {
      this.ui.form_group.removeClass('floating-label-active');
    }
  },

  handleSubmit: function fHandleSubmit(event) {
    event.preventDefault();

    const username = this.ui.username.val();
    if (!username) {
      this.warningView.show(i18n.t('common:mandatory-field'));
      this.errorView.hide();
      this.ui.username.parent().addClass('has-error');
      this.ui.username.focus();
      return;
    }

    this.model.set({
      username,
    });

    $.ajax({
      url:    ajaxOptions.url,
      method: ajaxOptions.method,
      data:   {
        username:      this.model.get('username'),
        nativeClient:  simpleStorage.get(Constants.nativeClient) ? 1 : 0,
        clientVersion: simpleStorage.get(Constants.nativeClientVersion) || '',
        authorize:     this.model.get('authorize'),
        inviteKey:     this.model.get('inviteKey') || '',
      },
    })
      .done((body) => {
        if (body.nextStep === 'ERROR') {
          this.model.set(_.extend({}, body, (body.options || {})));
          navChannel.trigger(this.model.get('nextStep'));
          return;
        }

        this.model.set({
          loginSessionId: body.loginSessionId,
          nextStep:       body.nextStep,
          options:        body.options || {},
        });
        const options = body.nextStep === 'AUTH_PORTAL' ? {
          captcha:        !!body.captcha,
          captchaEnabled: !!body.captchaEnabled,
        } : null;
        if (!options || !options.captchaEnabled) {
          $(document.body).addClass('captcha-disabled');
        }
        navChannel.trigger(this.model.get('nextStep'), options);
      })
      .fail((error) => {
        if (console) {
          if (error.responseJSON && error.responseJSON.code && error.responseJSON.traceId) {
            console.error('Error', error.responseJSON.code, error.responseJSON.traceId);
          } else {
            console.error('Error happened', error);
          }
        }
        this.errorView.show(i18n.t('common:unknown-error'));
        this.warningView.hide();
      });
  },

  onRender: function fOnRender() {
    this.errorView = new ErrorView({
      el: this.ui.error,
    });
    this.warningView = new WarningView({
      el: this.ui.input_error,
    });

    const errCode = this.model.get('error');

    if (errCode) {
      if (errCode >= 30000 && errCode <= 30099) {
        this.errorView.hide();
        this.warningView.show(utils.mapErrorCodeToMessage(errCode));
      } else {
        this.warningView.hide();
        this.errorView.show(utils.mapErrorCodeToMessage(errCode));
      }
    }
    setTimeout(() => {
      if (!$(document.body).hasClass('native-client')) {
        this.ui.sub_message.removeClass('hidden');
      }
      this.ui.username.focus();
    }, 1); // Doesn't work without timeout, cba with proper fix
  },
});

module.exports = UserNameView;
